import React, { useState, useEffect } from 'react';
import FormField from '../common/FormField';
import { Check, Loader2 } from 'lucide-react';

interface SektorData {
  [key: string]: {
    musyrif: string[];
    pelajar: {
      [key: string]: string[];
    };
  };
}

const sektorData: SektorData = {
  'Cangkuang': {
    musyrif: ['Anggara', 'Utis', 'Usman', 'Imam', 'Indra Gumilar', 'Handiman', 'Toni'],
    pelajar: {
      'Anggara': ['Hari', 'Utis', 'Usman'],
      'Utis': ['Husen'],
      'Usman': ['Indra'],
      'Imam': ['Anggara'],
      'Indra Gumilar': ['Aep'],
      'Handiman': ['Muchtar'],
      'Toni': ['Erlan']
    }

  },
  'Pangalengan': {
    musyrif: ['Dani', 'Mama Abdul Kelompok 1', 'Mama Abdul Kelompok 2', 'Mama Abdul Kelompok 3', 'Rifki J', 'Ust Dede Bojongsoang'],
    pelajar: {
      'Dani': ['Mama Abdul', 'Rifki J'],
      'Mama Abdul Kelompok 1': ['Rikrik', 'Abi', 'Pak Enjang', 'Fajar', 'Syarif'],
      'Mama Abdul Kelompok 2': ['Mulyana Cidaun'],
      'Mama Abdul Kelompok 3': ['Pak Eman', 'Pak Usan RT', 'Pak Yana'],
      'Rifki J': ['Danis', 'Harry', 'Opik', 'Ujang'],
      'Ust Dede Bojongsoang': ['Indra Gumilar']
    }
  },
  'Banjaran': {
    musyrif: ['Agnan', 'Aziz', 'Riki', 'Imam', 'Toni', 'Nandar','Muchtar', 'Indra Gumilar', 'Asep Kelompok 1', 'Asep Kelompok 2', 'Engkos', 'Ito', 'Mulyana'],
    pelajar: {
      'Agnan': ['Iman', 'Furqon', 'Arif'],
      'Aziz': ['Sofian', 'Hidayat', 'Tio', 'Ridwan'],
      'Riki': ['Agnan', 'Toni', 'Nandar'],
      'Imam': ['Asep', 'Rahmat', 'Deni P', 'Engkos'],
      'Toni': ['Arba', 'Fardan', 'Yusuf'],
      'Nandar': ['Marno'],
      'Muchtar': ['Nurdin', 'Riki', 'Aziz', 'Budi'],
      'Indra Gumilar': ['Imam', 'Mulyana', 'Dani'],
      'Asep Kelompok 1': ['Toha', 'Amin', 'Ridwan Muhammad'],
      'Asep Kelompok 2': ['Sadarudin'],
      'Engkos': ['Ito', 'Iqbal'],
      'Ito': ['Hanif', 'Refky'],
      'Mulyana': ['Pak Achmad', 'Rizki', 'Agung']
    }
  }
};

interface FormData {
  sektor: string;
  musyrif: string;
  pelajar: string;
  usia: string;
  tempatTinggal: string;
  pekerjaan: string;
  kitab: string;
}

const ContactForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    sektor: '',
    musyrif: '',
    pelajar: '',
    usia: '',
    tempatTinggal: '',
    pekerjaan: '',
    kitab: ''
  });

  const [musyrifList, setMusyrifList] = useState<string[]>([]);
  const [pelajarList, setPelajarList] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    if (formData.sektor) {
      setMusyrifList(sektorData[formData.sektor].musyrif);
      setFormData(prev => ({ ...prev, musyrif: '', pelajar: '' }));
    }
  }, [formData.sektor]);

  useEffect(() => {
    if (formData.sektor && formData.musyrif) {
      const pelajarData = sektorData[formData.sektor].pelajar;
      const selectedMusyrifPelajar = pelajarData[formData.musyrif] || [];
      setPelajarList(selectedMusyrifPelajar);
      setFormData(prev => ({ ...prev, pelajar: '' }));
    }
  }, [formData.sektor, formData.musyrif]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbzFbldssuUsrnGqih6jFln8YPfiKGiw4vkX_qto94QGIV9c9Z6OGkzfkDkai-6-o3J6/exec', {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'text/plain',
        },
        body: JSON.stringify({
          data: [
            new Date().toLocaleString('id-ID'),
            formData.sektor,
            formData.musyrif,
            formData.pelajar,
            formData.usia,
            formData.tempatTinggal,
            formData.pekerjaan,
            formData.kitab
          ],
          sheet: 'Kontakan List'
        })
      });

      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        setFormData({
          sektor: '',
          musyrif: '',
          pelajar: '',
          usia: '',
          tempatTinggal: '',
          pekerjaan: '',
          kitab: ''
        });
        setCurrentStep(1);
      }, 2000);
    } catch (error) {
      console.error('Error:', error);
      alert('Terjadi kesalahan saat menyimpan data. Silakan coba lagi.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const nextStep = () => {
    if (currentStep < 3) setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  return (
    <div className="max-w-2xl mx-auto p-8 bg-gradient-to-br from-white to-green-50 rounded-2xl shadow-lg">
      {/* Progress Bar */}
      <div className="mb-8">
        <div className="flex justify-between items-center">
          <div className={`w-1/3 text-center ${currentStep >= 1 ? 'text-emerald-600' : 'text-gray-400'}`}>
            <div className={`w-10 h-10 mx-auto rounded-full flex items-center justify-center border-2 transform transition-all duration-300 ${currentStep >= 1 ? 'bg-emerald-100 border-emerald-500 scale-110' : 'border-gray-300'}`}>
              1
            </div>
            <div className="mt-2 text-sm font-medium">Data Dasar</div>
          </div>
          <div className={`w-1/3 text-center ${currentStep >= 2 ? 'text-emerald-600' : 'text-gray-400'}`}>
            <div className={`w-10 h-10 mx-auto rounded-full flex items-center justify-center border-2 transform transition-all duration-300 ${currentStep >= 2 ? 'bg-emerald-100 border-emerald-500 scale-110' : 'border-gray-300'}`}>
              2
            </div>
            <div className="mt-2 text-sm font-medium">Informasi Pribadi</div>
          </div>
          <div className={`w-1/3 text-center ${currentStep >= 3 ? 'text-emerald-600' : 'text-gray-400'}`}>
            <div className={`w-10 h-10 mx-auto rounded-full flex items-center justify-center border-2 transform transition-all duration-300 ${currentStep >= 3 ? 'bg-emerald-100 border-emerald-500 scale-110' : 'border-gray-300'}`}>
              3
            </div>
            <div className="mt-2 text-sm font-medium">Informasi Pembelajaran</div>
          </div>
        </div>
        <div className="relative mt-4">
          <div className="absolute top-0 left-0 h-2 bg-gray-200 w-full rounded-full overflow-hidden">
            <div 
              className="absolute top-0 left-0 h-full bg-gradient-to-r from-emerald-400 to-emerald-600 rounded-full transition-all duration-500 ease-out"
              style={{ width: `${((currentStep - 1) / 2) * 100}%` }}
            />
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-8">
        {currentStep === 1 && (
          <div className="space-y-6 animate-fadeIn">
            <h3 className="text-xl font-semibold text-gray-800 mb-6 border-b pb-2 border-emerald-200">Data Dasar</h3>
            <FormField
              label="Sektor"
              name="sektor"
              type="select"
              value={formData.sektor}
              onChange={handleChange}
              options={Object.keys(sektorData).map(sektor => ({ value: sektor, label: sektor }))}
              required
              className="transition-all duration-300 hover:border-emerald-400 focus:ring-emerald-500"
            />

            <FormField
              label="Musyrif"
              name="musyrif"
              type="select"
              value={formData.musyrif}
              onChange={handleChange}
              options={musyrifList.map(musyrif => ({ value: musyrif, label: musyrif }))}
              required
              className="transition-all duration-300 hover:border-emerald-400 focus:ring-emerald-500"
            />

            <FormField
              label="Pelajar"
              name="pelajar"
              type="select"
              value={formData.pelajar}
              onChange={handleChange}
              options={pelajarList.map(pelajar => ({ value: pelajar, label: pelajar }))}
              required
              className="transition-all duration-300 hover:border-emerald-400 focus:ring-emerald-500"
            />
          </div>
        )}

        {currentStep === 2 && (
          <div className="space-y-6 animate-fadeIn">
            <h3 className="text-xl font-semibold text-gray-800 mb-6 border-b pb-2 border-emerald-200">Informasi Pribadi</h3>
            <FormField
              label="Usia"
              name="usia"
              type="number"
              value={formData.usia}
              onChange={handleChange}
              required
              min="1"
              className="transition-all duration-300 hover:border-emerald-400 focus:ring-emerald-500"
            />

            <FormField
              label="Tempat Tinggal"
              name="tempatTinggal"
              type="text"
              value={formData.tempatTinggal}
              onChange={handleChange}
              required
              placeholder="Masukkan alamat lengkap"
              className="transition-all duration-300 hover:border-emerald-400 focus:ring-emerald-500"
            />

            <FormField
              label="Pekerjaan"
              name="pekerjaan"
              type="text"
              value={formData.pekerjaan}
              onChange={handleChange}
              required
              placeholder="Contoh: Guru, Wiraswasta, dll"
              className="transition-all duration-300 hover:border-emerald-400 focus:ring-emerald-500"
            />
          </div>
        )}

        {currentStep === 3 && (
          <div className="space-y-6 animate-fadeIn">
            <h3 className="text-xl font-semibold text-gray-800 mb-6 border-b pb-2 border-emerald-200">Informasi Pembelajaran</h3>
            <FormField
              label="Kitab yang Dipelajari"
              name="kitab"
              type="text"
              value={formData.kitab}
              onChange={handleChange}
              required
              placeholder="Masukkan nama kitab"
              className="transition-all duration-300 hover:border-emerald-400 focus:ring-emerald-500"
            />
          </div>
        )}

        <div className="flex justify-between pt-8">
          {currentStep > 1 && (
            <button
              type="button"
              onClick={prevStep}
              className="px-6 py-2.5 text-sm font-medium text-emerald-600 bg-emerald-50 rounded-lg hover:bg-emerald-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 transition-all duration-300 shadow-sm hover:shadow"
            >
              Sebelumnya
            </button>
          )}
          
          {currentStep < 3 ? (
            <button
              type="button"
              onClick={nextStep}
              className="ml-auto px-6 py-2.5 text-sm font-medium text-white bg-gradient-to-r from-emerald-500 to-emerald-600 rounded-lg hover:from-emerald-600 hover:to-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 transition-all duration-300 shadow-md hover:shadow-lg transform hover:-translate-y-0.5"
            >
              Selanjutnya
            </button>
          ) : (
            <button
              type="submit"
              disabled={isSubmitting}
              className="ml-auto inline-flex items-center px-6 py-2.5 text-sm font-medium text-white bg-gradient-to-r from-emerald-500 to-emerald-600 rounded-lg hover:from-emerald-600 hover:to-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-300 shadow-md hover:shadow-lg transform hover:-translate-y-0.5"
            >
              {isSubmitting ? (
                <>
                  <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                  Menyimpan...
                </>
              ) : (
                'Simpan Data'
              )}
            </button>
          )}
        </div>
      </form>

      {/* Success Message */}
      {showSuccess && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black bg-opacity-40 backdrop-blur-sm"></div>
          <div className="relative bg-white rounded-xl p-8 shadow-2xl transform transition-all duration-300 scale-100 animate-fadeIn">
            <div className="flex flex-col items-center">
              <div className="w-16 h-16 rounded-full bg-gradient-to-br from-emerald-100 to-emerald-200 flex items-center justify-center mb-4 animate-bounce">
                <Check className="w-8 h-8 text-emerald-600" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">Berhasil!</h3>
              <p className="text-sm text-gray-600">Data telah berhasil disimpan</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactForm;