import React from 'react';
import { BookMarked } from 'lucide-react';
import KajianForm from './KajianForm';

const KajianPage: React.FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-blue-100 p-6">
      <div className="max-w-2xl mx-auto">
        <div className="text-center mb-8">
          <div className="inline-flex items-center justify-center w-16 h-16 bg-blue-600 rounded-full mb-4">
            <BookMarked className="w-8 h-8 text-white" />
          </div>
          <h1 className="text-3xl font-bold text-gray-800">Kajian</h1>
          <p className="text-gray-600 mt-2">Form Kehadiran Kajian</p>
        </div>
        
        <div className="bg-white rounded-xl shadow-lg p-6 md:p-8">
          <KajianForm />
        </div>
      </div>
    </div>
  );
};

export default KajianPage;