import React, { useState } from 'react';
import { MutabaahChecklist } from './MutabaahChecklist';
import { RegistrationForm } from './RegistrationForm';
import { User, ChecklistData } from '../../types';

const initialChecklist: ChecklistData = {
  shuburJamaah: false,
  quranReading: false,
  shalat_subuh: false,
  shalat_dzuhur: false,
  shalat_ashar: false,
  shalat_maghrib: false,
  shalat_isya: false,
  tilawah: false,
  dzikir_pagi: false,
  dzikir_petang: false,
  submissionDate: '',
};

export default function MutabaahPage() {
  const [user, setUser] = useState<User | null>(null);
  const [checklist, setChecklist] = useState<ChecklistData>(initialChecklist);

  const handleRegister = (newUser: User) => {
    console.log('Setting user:', newUser);
    setUser(newUser);
  };

  if (!user) {
    return <RegistrationForm onRegister={handleRegister} />;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <MutabaahChecklist
        user={user}
        checklist={checklist}
        onChecklistChange={setChecklist}
      />
    </div>
  );
}
