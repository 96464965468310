import React, { useState, useEffect } from 'react';
import { Check, Loader2, Book, Calendar, User, Users, FileText, MessageSquare } from 'lucide-react';
import FormField from '../common/FormField';

interface StudentReport {
  name: string;
  attendance: string;
  control: string;
  contact: string;
  fee: string;
  bulletins: string;
}

interface FormData {
  teacherName: string;
  courseDate: string;
  bookPage: string;
  youthStatus: string;
  periodStart: string;
  periodEnd: string;
  studentReports: StudentReport[];
  youthConditionNotes: string;
  contactNotes: string;
}

interface TeacherReportFormProps {
  registrationData: {
    sektor: string;
    musyrif: string;
    kategori: string;
    pelajar: string[];
  };
}

const TeacherReportForm: React.FC<TeacherReportFormProps> = ({ registrationData }) => {
  const [formData, setFormData] = useState<FormData>({
    teacherName: registrationData.musyrif,
    courseDate: new Date().toISOString().split('T')[0],
    bookPage: '',
    youthStatus: '',
    periodStart: new Date().toISOString().split('T')[0],
    periodEnd: new Date().toISOString().split('T')[0],
    studentReports: registrationData.pelajar.map(name => ({
      name,
      attendance: '',
      control: '',
      contact: '',
      fee: '',
      bulletins: ''
    })),
    youthConditionNotes: '',
    contactNotes: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleStudentReportChange = (index: number, field: keyof StudentReport, value: string) => {
    setFormData(prev => ({
      ...prev,
      studentReports: prev.studentReports.map((report, i) => 
        i === index ? { ...report, [field]: value } : report
      )
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const payload = {
        ...formData,
        sektor: registrationData.sektor,
        kategori: registrationData.kategori,
        timestamp: new Date().toISOString()
      };

      await fetch('https://script.google.com/macros/s/AKfycbz8RzOnwK8sj2-sxSDFWVFh9iupKxNkylOYYmvyJwFfd0PLeMoqSIireXWXJbO90d4e/exec', {
        method: 'POST',
        body: JSON.stringify({
          data: payload,
          sheet: 'Laporan Guru'
        }),
        mode: 'no-cors'
      });

      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        // Reset form or redirect
      }, 2000);
    } catch (error) {
      console.error('Error:', error);
      alert('Terjadi kesalahan saat menyimpan data. Silakan coba lagi.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-6">
          <div className="flex items-center space-x-2 text-green-600 mb-4">
            <User className="w-5 h-5" />
            <h2 className="text-lg font-semibold">Informasi Guru</h2>
          </div>
          
          <FormField
            label="Nama Guru"
            name="teacherName"
            type="text"
            value={formData.teacherName}
            onChange={handleChange}
            required
          />

          <FormField
            label="Tanggal Kursus"
            name="courseDate"
            type="date"
            value={formData.courseDate}
            onChange={handleChange}
            required
          />
        </div>

        <div className="space-y-6">
          <div className="flex items-center space-x-2 text-green-600 mb-4">
            <Book className="w-5 h-5" />
            <h2 className="text-lg font-semibold">Detail Pembelajaran</h2>
          </div>

          <FormField
            label="Kitab"
            name="bookPage"
            type="select"
            value={formData.bookPage}
            onChange={handleChange}
            required
            options={[
              { value: "Materi HU", label: "Materi HU" },
              { value: "Materi CP", label: "Materi CP" },
              { value: "Nidzam Islam", label: "Nidzam Islam" },
              { value: "Takatul", label: "Takatul" },
              { value: "Mafahim", label: "Mafahim" },
              { value: "Daulah", label: "Daulah" },
              { value: "Ajhizah", label: "Ajhizah" },
              { value: "Ijtimai", label: "Ijtimai" },
              { value: "Iqtishodi", label: "Iqtishodi" },
              { value: "Amwal", label: "Amwal" },
              { value: "Mafahim Siyasi", label: "Mafahim Siyasi" },
              { value: "Syakhsiyah", label: "Syakhsiyah" }
            ]}
          />

          <FormField
            label="Status Pemuda"
            name="youthStatus"
            type="select"
            value={formData.youthStatus}
            onChange={handleChange}
            required
            options={[
              { value: "Karyawan", label: "Karyawan" },
              { value: "Pelajar", label: "Pelajar" },
              { value: 'hu', label: 'HU' },
              { value: 'cp', label: 'CP' }
            ]}
          />
        </div>
      </div>

      <div className="space-y-6">
        <div className="flex items-center space-x-2 text-green-600 mb-4">
          <Calendar className="w-5 h-5" />
          <h2 className="text-lg font-semibold">Periode Laporan</h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <FormField
            label="Tanggal Mulai"
            name="periodStart"
            type="date"
            value={formData.periodStart}
            onChange={handleChange}
            required
          />

          <FormField
            label="Tanggal Selesai"
            name="periodEnd"
            type="date"
            value={formData.periodEnd}
            onChange={handleChange}
            required
          />
        </div>
      </div>

      <div className="space-y-6">
        <div className="flex items-center space-x-2 text-green-600 mb-4">
          <Users className="w-5 h-5" />
          <h2 className="text-lg font-semibold">Laporan Pelajar</h2>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">No</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nama Pemuda</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Kehadiran</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Kontrol</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Kontak</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">SPP</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Jml Sebar Buletin</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {formData.studentReports.map((report, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{index + 1}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{report.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="text"
                      value={report.attendance}
                      onChange={(e) => handleStudentReportChange(index, 'attendance', e.target.value)}
                      className="w-full px-2 py-1 border rounded-md"
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="text"
                      value={report.control}
                      onChange={(e) => handleStudentReportChange(index, 'control', e.target.value)}
                      className="w-full px-2 py-1 border rounded-md"
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="text"
                      value={report.contact}
                      onChange={(e) => handleStudentReportChange(index, 'contact', e.target.value)}
                      className="w-full px-2 py-1 border rounded-md"
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="text"
                      value={report.fee}
                      onChange={(e) => handleStudentReportChange(index, 'fee', e.target.value)}
                      className="w-full px-2 py-1 border rounded-md"
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="text"
                      value={report.bulletins}
                      onChange={(e) => handleStudentReportChange(index, 'bulletins', e.target.value)}
                      className="w-full px-2 py-1 border rounded-md"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="mt-6 bg-gray-50 rounded-lg p-4">
        <h3 className="text-lg font-medium text-gray-900 mb-3">Keterangan Kehadiran:</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div className="flex items-start space-x-2">
            <span className="font-bold text-green-600">V</span>
            <span className="text-gray-600">Hadir, Tepat Waktu</span>
          </div>
          <div className="flex items-start space-x-2">
            <span className="font-bold text-yellow-600">T</span>
            <span className="text-gray-600">Hadir, Terlambat kurang dari 15 menit</span>
          </div>
          <div className="flex items-start space-x-2">
            <span className="font-bold text-red-600">S</span>
            <span className="text-gray-600">Tidak Hadir, Karena Sakit</span>
          </div>
          <div className="flex items-start space-x-2">
            <span className="font-bold text-blue-600">I</span>
            <span className="text-gray-600">Tidak Hadir, Tapi Ijin (syar'i)</span>
          </div>
          <div className="flex items-start space-x-2">
            <span className="font-bold text-red-600">A</span>
            <span className="text-gray-600">Tidak Hadir, Tanpa Keterangan</span>
          </div>
          <div className="flex items-start space-x-2">
            <span className="font-bold text-gray-600">G</span>
            <span className="text-gray-600">Tidak ada kursus karena Guru tidak hadir</span>
          </div>
        </div>
      </div>

      <div className="space-y-6">
        <div className="flex items-center space-x-2 text-green-600 mb-4">
          <MessageSquare className="w-5 h-5" />
          <h2 className="text-lg font-semibold">Catatan</h2>
        </div>

        <FormField
          label="Catatan mengenai kondisi Pemuda"
          name="youthConditionNotes"
          type="textarea"
          value={formData.youthConditionNotes}
          onChange={handleChange}
          required
        />

        <FormField
          label="Program Kontak & Dakwah Berbasis Kelompok Halqah bersama Daris (WAJIB)"
          name="contactNotes"
          type="textarea"
          value={formData.contactNotes}
          onChange={handleChange}
          required
        />
        <div className="bg-green-50 border border-green-200 rounded-lg p-4">
          <p className="text-sm text-gray-600 leading-relaxed">
            Silahkan Musyrif membuat program kontak ataupun dakwah dengan para daris, baik itu berupa kontak bareng-bareng musyrif dengan darisnya ataupun buat program kajian-kajian, seperti kajian umum di masjid, durusul masajid, sebar buletin dsb yg dirasa bisa di maksimalkan Program Dakwah Berbasis Management Kelompok Halqah bersama kel Halqahnya sesuai dengan kompetensi kel halqah.
          </p>
        </div>
      </div>

      <div className="flex justify-end pt-6">
        <button
          type="submit"
          disabled={isSubmitting}
          className="w-full sm:w-auto inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50"
        >
          {isSubmitting ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Menyimpan...
            </>
          ) : (
            <>
              <Check className="mr-2 h-4 w-4" />
              Simpan Laporan
            </>
          )}
        </button>
      </div>

      {showSuccess && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl">
            <div className="flex items-center justify-center mb-4">
              <Check className="h-12 w-12 text-green-500" />
            </div>
            <p className="text-center text-lg font-medium">
              Laporan berhasil disimpan!
            </p>
          </div>
        </div>
      )}
    </form>
  );
};

export default TeacherReportForm;
