import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown, X, Check } from 'lucide-react';

interface Option {
  value: string;
  label: string;
}

interface FormFieldProps {
  label: string;
  name: string;
  type: 'text' | 'select' | 'textarea' | 'date' | 'multiselect';
  value: string | string[];
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
  options?: Option[];
  required?: boolean;
}

const FormField: React.FC<FormFieldProps> = ({
  label,
  name,
  type,
  value,
  onChange,
  options,
  required
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const baseClassName = "w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent transition-colors duration-200";

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleMultiSelectChange = (optionValue: string) => {
    const currentValues = Array.isArray(value) ? value : [];
    const newValues = currentValues.includes(optionValue)
      ? currentValues.filter(v => v !== optionValue)
      : [...currentValues, optionValue];
    
    const syntheticEvent = {
      target: {
        name,
        value: newValues,
        options: newValues.map(v => ({ value: v, selected: true }))
      }
    } as unknown as React.ChangeEvent<HTMLSelectElement>;
    
    onChange(syntheticEvent);
  };

  const renderSelectedValues = () => {
    if (!Array.isArray(value) || value.length === 0) return 'Pilih ' + label;
    
    const selectedLabels = options
      ?.filter(opt => value.includes(opt.value))
      .map(opt => opt.label);
    
    return selectedLabels?.join(', ') || 'Pilih ' + label;
  };

  return (
    <div className="space-y-1">
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      
      {type === 'multiselect' ? (
        <div className="relative" ref={dropdownRef}>
          <button
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className={`${baseClassName} bg-white text-left flex justify-between items-center`}
          >
            <span className="truncate pr-4">{renderSelectedValues()}</span>
            <ChevronDown className={`w-5 h-5 transition-transform ${isOpen ? 'transform rotate-180' : ''}`} />
          </button>
          
          {isOpen && (
            <div className="absolute z-10 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-60 overflow-auto">
              {options?.map(option => {
                const isSelected = Array.isArray(value) && value.includes(option.value);
                return (
                  <div
                    key={option.value}
                    onClick={() => handleMultiSelectChange(option.value)}
                    className={`px-4 py-2 cursor-pointer flex items-center justify-between hover:bg-green-50 ${
                      isSelected ? 'bg-green-50' : ''
                    }`}
                  >
                    <span>{option.label}</span>
                    {isSelected && <Check className="w-4 h-4 text-green-600" />}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      ) : type === 'select' && options ? (
        <select
          id={name}
          name={name}
          value={value as string}
          onChange={onChange}
          required={required}
          className={`${baseClassName} bg-white`}
        >
          <option value="">Pilih {label}</option>
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ) : type === 'textarea' ? (
        <textarea
          id={name}
          name={name}
          value={value as string}
          onChange={onChange}
          required={required}
          className={`${baseClassName} min-h-[100px]`}
        />
      ) : type === 'date' ? (
        <input
          type="date"
          id={name}
          name={name}
          value={value as string}
          onChange={onChange}
          required={required}
          className={baseClassName}
        />
      ) : (
        <input
          type="text"
          id={name}
          name={name}
          value={value as string}
          onChange={onChange}
          required={required}
          className={baseClassName}
        />
      )}
    </div>
  );
};

export default FormField;
